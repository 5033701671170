$(document).ready(function() {
  window.initializeDatePicker = function() {
    $('input.date-picker').daterangepicker({
      autoUpdateInput:     false,
      autoApply:           true,
      singleDatePicker:    true,
      minYear:             2020,
      locale:              { format: 'DD.MM.YYYY' },
      showDropdowns:       true
    }).on('apply.daterangepicker', function(ev, picker) {
      picker.element.val(picker.startDate.format('DD.MM.YYYY'));
    }).on('cancel.daterangepicker', resetVal).keypress(function(e) {
      e.preventDefault();
    });

    $('input.date-range-picker').daterangepicker({
      autoUpdateInput:      false,
      showCustomRangeLabel: false,
      alwaysShowCalendars:  true,
      minYear:              2020,
      locale:               { format: 'DD.MM.YYYY' }
    }).on('apply.daterangepicker', function(ev, picker) {
      picker.element.val(`${picker.startDate.format('DD.MM.YYYY')} - ${picker.endDate.format('DD.MM.YYYY')}`);
    }).on('cancel.daterangepicker', resetVal).keypress(function(e) {
      e.preventDefault();
    });
  };

  initializeDatePicker();
});

const resetVal = (ev, picker) => {
  picker.element.val('');
};
