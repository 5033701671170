$(document).on("change", ".checkbox-filter", function(event) {
    /**
     * @type {HTMLAnchorElement}
     */
    var target = $($(this).data("target"))[0];
    if(!target) return;
    if(!target.hasAttribute("data-original-href")) {
        target.setAttribute("data-original-href", target.href);
    }

    var selection = [... $(".checkbox-filter:checked").map(function() { return $(this).val(); })];

    var link = target.getAttribute("data-original-href");
    if(selection.length > 0)
        link += "?filter=" + encodeURIComponent(selection.join(","));
    target.href = link;
});