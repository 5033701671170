import jquery from "jquery";
import "daterangepicker";
import "bootstrap";
import Rails from "rails-ujs";
import * as ActiveStorage from "activestorage";
import Dropzone from "dropzone";
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import "../javascripts/loading";
import "../javascripts/locale_translations";
import "../javascripts/open_popout";
import "../javascripts/daterangepicker";
import "../javascripts/direct_upload";
import "../javascripts/toasts";
import "../javascripts/tooltips";
import "../javascripts/checked_table_row";
import "../javascripts/moveto";
import "../javascripts/lightbox";
import "../javascripts/copy";
import "../javascripts/locale_filters";
import "ekko-lightbox";

window.Rails = Rails;
window.$ = jquery;

Rails.start();
ActiveStorage.start();
Dropzone.autoDiscover = false;

const application = Application.start();
const context = require.context("controllers", true, /\.js$/);

application.load(definitionsFromContext(context));

import "data-confirm-modal";

dataConfirmModal.setDefaults({
  commitClass: "btn-secondary",
  modalClass: "confirm-dialog",
});
