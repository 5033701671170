window.addEventListener("load", (evt) => {
    const finalQuestionCategory = document.querySelector("#final_question_category select")
    const finalParticipantType = document.querySelector("#final_participant_type select")
    if(typeof(finalQuestionCategory) == "undefined" || !finalQuestionCategory) return;
    const options = finalQuestionCategory.querySelectorAll("option")
    const clothing = document.querySelectorAll("#international_final_competitor_tshirt_size option")
    const guestOption = [...options].filter(option => option.textContent == "Country guest")[0]
    guestOption.style.display = "none"
    if(finalParticipantType != null){
        finalParticipantType.addEventListener("change", (event) => {
            const selectedOption = finalParticipantType.value
            console.log(selectedOption)
            if(selectedOption == "country_guest"){
                options.forEach(option => option.style.display = "none")
                clothing.forEach(option => {
                    if(option.value != "") option.style.display = "none";
                });
                $("#international_final_competitor_tshirt_size").val("");
                guestOption.selected = true;
                guestOption.style.display = ""
            } else {
                options.forEach(option => option.style.display = "")
                clothing.forEach(option => {
                    option.style.display = "";
                });
                $("#international_final_competitor_tshirt_size").val("");
                guestOption.style.display = "none" 
                options[0].selected = true;
            }
        })
    }
    if(finalParticipantType.value == "country_guest"){
        options.forEach(option => option.style.display = "none")
        clothing.forEach(option => {
            if(option.value != "") option.style.display = "none";
        });
        $("#international_final_competitor_tshirt_size").val("");
        guestOption.selected = true;
        guestOption.style.display = ""
    }
})