import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'note'];

  connect() {
    this.button = document.getElementsByName('submit')[0];
  }

  edit() {
    this.inputTarget.classList.remove('d-none');
    this.noteTarget.classList.add('d-none');
    this.button.disabled = false;
  }
}
