$(document).ready(function () {
  if ($('table.translations').length) {
    $(document).on('click', '#toggleTranslated', function () {
      if ($(this).text() == 'Show all') {
        $('table.translations tr.translated').show();
        $(this).text("Only untranslated");
      } else {
        $('table.translations tr.translated').hide();
        $(this).text("Show all");
      }
      return false;
    });

    $('.text-editor-quill').each(function () {
      newQuillEditor(this, "complex");
    });

    $(document).on('submit', 'table.translations form', function () {
      if ($(this).find('.ql-editor').length) {
        // save data from Quill
        $(this).find('#translation_value').val(btoa(unescape(encodeURIComponent($(this).find('.ql-editor')[0].innerHTML))));
      } else {
        $(this).find('#translation_value').val(btoa(unescape(encodeURIComponent($(this).find('#translation_value').val()))));
      }
    });

    $(document).on('click', '.translation-suggestion', function () {
      $(this).closest('td').find('textarea').val($(this).text());
      $(this).closest('td').find('input').click();
      return false;
    });
  }
});

const QuillToolbarOptions = {
  "basic": [
    ['bold', 'italic', 'underline'],                // toggled buttons
    [{'script': 'sub'}, {'script': 'super'}],       // superscript/subscript
  ],
  "simple": [
    ['bold', 'italic', 'underline'],                // toggled buttons
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{'script': 'sub'}, {'script': 'super'}],       // superscript/subscript
    ["better-table"],
    [{
      'background': ['#FF0', '#FF8000', '#F00', '#0F0']
    }],
    ['clean'],                                      // remove formatting button
    [{'direction': 'rtl'}],                         // text direction
  ],
  "complex": [
    ['bold', 'italic', 'underline'],                // toggled buttons
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{'script': 'sub'}, {'script': 'super'}],       // superscript/subscript
    [{
      'background': ['#FF0', '#FF8000', '#F00', '#0F0']
    }],
    [{'header': [1, 2, 3, 4, 5, 6, false]}],
    [{'color': []}, {'background': []}],            // dropdown with defaults from theme
    [{'align': []}],
    ["better-table"],
    ['clean'],                                      // remove formatting button
    [{'direction': 'rtl'}],                         // text direction
  ]
};

function newQuillEditor(editorElement, type) {
  const modules = {
    toolbar: QuillToolbarOptions[type]
  }
  if (typeof (quillEasyTable) != "undefined") {
    Quill.register({"modules/better-table": quillEasyTable})
    modules.table = false;
    modules["better-table"] = {
      operationMenu: {
        color: {
          colors: ['#4ba82e', 'transparent'],
          text: 'Background Colors:'
        }
      }
    }
    modules.keyboard = {
      bindings: quillEasyTable.keyboardBindings
    }
  }

  const quill = new Quill(editorElement, {
    modules,
    theme: 'snow'
  });

  window.gq = quill;

  $(editorElement).parent().on("click", ".ql-better-table", (evt) => {
    evt.preventDefault()
    evt.stopPropagation()
    const mod = quill.getModule("better-table")
    mod.insertTable(3, 3)
  })

  $(editorElement).parent().find(".ql-better-table").html(`<svg viewBox="0 0 18 18"> <rect class="ql-stroke" height="12" width="12" x="3" y="3"></rect> <rect class="ql-fill" height="2" width="3" x="5" y="5"></rect> <rect class="ql-fill" height="2" width="4" x="9" y="5"></rect> <g class="ql-fill ql-transparent"> <rect height="2" width="3" x="5" y="8"></rect> <rect height="2" width="4" x="9" y="8"></rect> <rect height="2" width="3" x="5" y="11"></rect> <rect height="2" width="4" x="9" y="11"></rect> </g> </svg>`);

}

window.newQuillEditor = newQuillEditor;
