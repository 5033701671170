function handleMoveTo() {
  console.log("Moving things around");
  $("[data-moveto]").each(function () {
    $(this).appendTo($(this).data("moveto"));
    $(this).removeAttr("data-moveto");
  });
}

$(document).on("turbolinks:load", function () {
  handleMoveTo();
});

$(handleMoveTo);
