import { Controller } from 'stimulus';
import moment from 'moment';

export default class extends Controller {
  static targets = ['placeholder', 'progress'];

  connect() {
    const that     = this;
    const deadline = this.placeholderTarget.getAttribute('data-until');

    this.refresh = setInterval(function() {
      let remainingSeconds = deadline - Math.round(Date.now() / 1000);

      if(remainingSeconds < 0) {
        clearInterval(that.refresh);
        document.getElementById('submit').click()

      } else {
        that.placeholderTarget.innerHTML = moment(remainingSeconds, 's').format('mm:ss');
        that.progressTarget.setAttribute('style', `width: ${remainingSeconds / 60 * 100}%`);
      }
    }, 1000);
  }
}
