import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form', 'input', 'dropzone', 'gallery'];

  preventDefault(e) {
    e.preventDefault()
    e.stopPropagation()
  }

  connect() {
    this.newImages = [];

    if(this.hasDropzoneTarget) {
      let dropArea = this.dropzoneTarget;

      dropArea.addEventListener('dragenter', this.preventDefault, false);
      dropArea.addEventListener('dragleave', this.preventDefault, false);
      dropArea.addEventListener('dragover',  this.preventDefault, false);
      dropArea.addEventListener('drop',      this.preventDefault, false);

      dropArea.addEventListener('dragenter ', () => { this.highlight() }, false)
      dropArea.addEventListener('dragover', () => { this.highlight() }, false)
      dropArea.addEventListener('dragleave', () => { this.unhighlight() }, false)
      dropArea.addEventListener('drop', () => { this.unhighlight() }, false)
      dropArea.addEventListener('drop', (e) => { this.handleDrop(e) }, false)
    }
  }

  chooseFile() {
    this.inputTarget.click();
  }

  handleDrop(e) {
    this.inputTarget.files = e.dataTransfer.files;
    this.inputTarget.dispatchEvent(new Event('change'));
  }

  highlight() {
    this.dropzoneTarget.classList.add('highlight');
  }

  previewFile() {
    this.newImages.forEach(image => this.galleryTarget.removeChild(image));
    this.newImages = [];

    ([...this.inputTarget.files]).forEach(file => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        let imgContent = document.createElement('div')
        let img = document.createElement('img')
        img.src = reader.result
        imgContent.appendChild(img)
        imgContent.classList.add('img-thumbnail')
        this.galleryTarget.appendChild(imgContent)
        this.newImages.push(imgContent);
      }
    });
  }

  unhighlight() {
    this.dropzoneTarget.classList.remove('highlight');
  }

  upload() {
    document.getElementById('loading').classList.remove('d-none');
    this.formTarget.submit();
  }
}
