$(document).ready(function () {
  if ($(".datagrid .checkbox input").length) {
    $(".datagrid .checkbox input").on("change", function (e) {
      let row = $(this).closest("tr");

      if (e.target.checked) {
        row.addClass("tr--highlighted");
      } else {
        row.removeClass("tr--highlighted");
      }
    });
  }

  if ($(".datagrid tr[data-href]").length) {
    $(".datagrid tr[data-href] td[class!=actions][class!=checkbox]").on(
      "click",
      function () {
        window.location = $(this).closest("tr").data("href");
      }
    );
  }
});
