$(document).ready(() => {
  const userBadge = $(".nav-item--user");
  const popoutMenu = $(".popout");

  userBadge.on("click", () => {
    popoutMenu.toggleClass("open");
  });

  $(document).on("click", event => {
    if (
      !$(event.target).closest(".nav-item--user").length &&
      !$(event.target).closest(".popout").length
    ) {
      popoutMenu.removeClass("open");
    }
  });
});

$(document).ready(() => {
  const dropdownBtn = $(".dropdown__button");
  const dropdownMenu = $(".dropdown__menu");

  dropdownBtn.on("click", () => {
    dropdownMenu.toggleClass("open");
  });

  $(document).on("click", event => {
    if (
      !$(event.target).closest(".dropdown__button").length &&
      !$(event.target).closest(".dropdown__menu").length
    ) {
      dropdownMenu.removeClass("open");
    }
  });
});
