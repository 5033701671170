import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['container', 'input', 'signedId'];

  destroy() {
    if(this.hasInputTarget)    { this.inputTarget.value = 'true'; }
    if(this.hasSignedIdTarget) { this.signedIdTarget.remove(); }
    this.containerTarget.classList.add('d-none');
  }
}
