import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['link'];

  open() {
    $('#modal')[0].innerHTML = '';
    $('#modal').load(this.linkTarget.href, function(e){
      $('#modal').modal('show').blur();
    });
  }
}
