import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form'];

  filter() {
    document.getElementById('loading').classList.remove('d-none');
    this.formTarget.submit();
  }
}
