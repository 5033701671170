import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['selectAll', 'select'];

  check() {
    this.toggleActions();
  }

  checkAll() {
    const checked = this.selectAllTarget.checked;
    this.selectTargets.forEach(function(input) {
      input.checked = checked;
      input.dispatchEvent(new Event('change'));
    });

    this.toggleActions();
  }

  toggleActions() {
    if(this.selectTargets.some(function(input) { return input.checked })) {

    } else {

    }
  }
}
